.App {
  background-color: #282c34;
  width: 100%;
  display: flex;
  flex-direction: column;
  font-size: calc(15px + 3vmin);
  color: white;
  font-weight: bold;
  text-align: center;
  align-items: center;
  justify-content: center;
}

.nav-header {
  width: 100%;
}

.nav-header ul{
  list-style: none;
  display: flex;
  justify-content: flex-end;
  margin: 20px 0;
}

.nav-header ul li{
  margin: 0;
  padding: 0;
}

.nav-header ul li a{
  font-weight: normal;
  font-size: 14px;
  padding-right: 20px;
  text-decoration: none;
  color: #ffffff;
}
.nav-header ul li:hover {
  transition: 0.2s;
  transform: scale(1.15);
}

.App-Content {
  display: flex;
  text-align: center;
  justify-content: center;
  width: 100%;
}

.App-content-text {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  width: 55%;
  padding-left: 12%;
  height: 70vh;
}

#App-Text {
  display: flex;
  align-items: flex-start;
  justify-content: center;
  flex-direction: column;
  text-align: left !important;
}

#App-Text h3 {
  margin: 0;
}

.App-Content-Image {
  width: 55%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.App-Content-Image img {
  width: 90%;
  object-fit: cover;
  border-radius: 50%;
}

.contacts-list {
  list-style: none;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  animation-name: move;
  animation-duration: 2.2s;
  /* animation-delay: 12.6s; */
  animation-fill-mode: both;
}

ul {
  padding: 0;
  margin: 10px 0;
}

.contacts-item {
  display: flex;
  justify-content: center;
  align-items: center;
  padding-right: 26px;
}

.contacts-item:hover {
  transition: 0.2s;
  transform: scale(1.05);
}

.App-link {
  color: #61dafb;
}

.App-Text-Description {
  font-size: 26px;
  font-weight: normal;
}

.App-Scroll-Button {
  overflow: hidden;
  animation-name: move;
  animation-duration: 2.2s;
  animation-delay: 0s;
  animation-fill-mode: both;
  transition: 0.2s;
}

.App-Scroll-Button:hover {
  position: relative;
  top: -3px;
  cursor: pointer;
}

.App-Scroll-Button:active {
  position: relative;
  top: 3px;
  cursor: pointer;
  transition: 0.2s;
}

@media only screen and (max-width: 720px) { /*900px*/

  .App-Content-Image {
    display: none;
  }

  .App-content-text {
    width: 100%;
    padding: 0 20px;
    align-items: center;
  }

  #App-Text {
    text-align: center !important;
    align-items: center;
  }

  .contacts-item {
    padding: 0;
    width: 80px;
  }

}

@media only screen and (max-width: 480px) {

  .nav-header {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  /* .App {
    padding: 40 35px 0 35px;
    font-size: 26px;
  }

  .App-profile {
    top: 5px; 
    position: absolute;
    align-self: center;
    width: 280px;
    height: auto;
   }*/
/*
  .nav-header ul li{
    font-size: 12px;
  }  */
}

@media only screen and (max-width: 380px) {
  /* .App-profile {
    top: 25px; 
    position: absolute;
    align-self: center;
    width: 200px;
    height: auto;
  }

  .App {
    padding: 68 35px 0 35px;
    font-size: 26px;
  } */
}

@keyframes move {
  from{
    opacity: 0;
    transform: translateY(-70%);
  }to {
    opacity: 1;
    transform: translateY(0);
  }
}